<template>
  <div class="DonationSummary Block" data-testid="donation-summary-block">
    <div
      class="Block--Title"
      :class="{ 'Block--Title-Active': isActiveBlock }"
      data-testid="title-summary"
    >
      <fa icon="check-square" aria-hidden="true" />
      {{ $t('campaign.mySummary') }}
    </div>
    <div class="Block--Content">
      <table>
        <thead>
          <tr>
            <th>{{ $t('campaign.selection') }}</th>
            <th>{{ $t('campaign.donation') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t('campaign.donation') }}</td>
            <td data-testid="donation-amount">
              <b>{{ convertToEuros(price) }} €</b>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('campaign.step.summary.contributingToHelloAsso') }}
              <div class="DonationSummary--ContributionLinks">
                <ha-button
                  class="Toggle--Modal-WhyDonate"
                  variant="link"
                  :data-ux="`Forms_${metadata.type}${
                    isWidget ? 'Widget' : ''
                  }_StepSummary_Toggle_ModalContributionExplication`"
                  data-testid="button-cv-explication"
                  @click="displayModal('explication')"
                >
                  {{ $t('campaign.step.summary.whyContribute') }}
                </ha-button>
                -
                <ha-button
                  class="Toggle--Modal-UpdateDonation"
                  variant="link"
                  :data-ux="`Forms_${metadata.type}${
                    isWidget ? 'Widget' : ''
                  }_StepSummary_Toggle_ModalContributionSelection`"
                  data-testid="button-change-contribution"
                  @click="openModalModify"
                >
                  {{ $t('button.update') }}
                </ha-button>
              </div>
            </td>
            <td data-testid="cv-amount">
              <b>{{ convertToEuros(tipSelected) }} €</b>
            </td>
          </tr>
          <tr>
            <td>
              {{
                tierType == 'MonthlyDonation' ? $t('campaign.monthlyTotal') : $t('campaign.total')
              }}
            </td>
            <td data-testid="total-amount">
              <b>{{ convertToEuros(price + tipSelected) }} €</b>
            </td>
          </tr>
        </tbody>
      </table>
      <NoticeVoluntaryContribution />
    </div>
    <LegalCheckboxes class="DonationSummary--Validation" :is-tipable="isTipable" />
    <ModalContributionExplication
      v-if="displayModalContributionExplication"
      @close="displayModalContributionExplication = false"
    />
    <ModalModify
      v-if="displayModalContributionSelection"
      :tiers-amount="price"
      :payment-installments-number="cart.items?.[0].paymentFrequency"
      @close="displayModalContributionSelection = false"
    />
  </div>
</template>

<script>
import { HaButton } from '@ha/components'
import { functions } from '@ha/helpers'
import {
  donationRequiredPersonalInformationsFields,
  donationRequiredCompanyFields
} from '@/helpers/enums'
import ModalContributionExplication from '@/components/voluntary-contributions/ModalContributionExplication.vue'
import NoticeVoluntaryContribution from '@/components/voluntary-contributions/NoticeVoluntaryContribution.vue'
import { getTipSuggestion } from '@/helpers/formUtils'
import usePayerPersonalInformations from '@/components/payers/usePayerPersonalInformations'
import useTracking from '@/composables/useTracking'
import LegalCheckboxes from '@/components/carts/LegalCheckboxes.vue'
import useLegalCheckbox from '@/components/carts/useLegalCheckbox'
import useCart from '@/composables/useCart'
import useStoreData from '@/composables/useStoreData'
import useSale from '@/components/forms/sales/useSale'
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'
import ModalModify from '@/components/voluntary-contributions/partials/ModalModify.vue'

export default {
  name: 'DonationSummary',
  components: {
    HaButton,
    ModalContributionExplication,
    LegalCheckboxes,
    NoticeVoluntaryContribution,
    ModalModify
  },
  inject: ['isWidget'],
  props: {
    selectedTier: Object
  },
  setup() {
    const { personalInformations, customFields } = usePayerPersonalInformations()
    const { trackingMethods } = useTracking()
    const { setLegalCheckboxes } = useLegalCheckbox()
    const { onFirstClick, onTipsUpdate } = trackingMethods
    const { cart } = useCart()
    const { storeRouteParams } = useStoreData()
    const { setVente } = useSale()
    const { setTipSuggestedFromTierAmount, tipSelected, tipSuggested, tipSelectedInEuros } =
      useVoluntaryContribution()

    setLegalCheckboxes()
    setVente()

    return {
      personalInformations,
      customFields,
      onFirstClick,
      onTipsUpdate,
      cart,
      storeRouteParams,
      setTipSuggestedFromTierAmount,
      tipSelectedInEuros,
      tipSelected
    }
  },
  data() {
    return {
      tipData: {},
      displayModalContributionExplication: false,
      displayModalContributionSelection: false
    }
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    realAmount() {
      return this.$store.getters['carts/getRealAmount'](this.storeRouteParams)
    },
    price() {
      return this.selectedTier?.price || 0
    },
    tierType() {
      return this.selectedTier?.tierType
    },
    isTipable() {
      return this.tipSelected > 0
    },
    organization() {
      return this.$store.getters['organizations/getOrganization'](this.metadata.organization)
    },
    hasAllCustomFieldsRequired() {
      if (!this.selectedTier) return false
      const requiredFields =
        this.selectedTier.customFields?.filter(item => item.isRequired).map(item => item.id) || []
      return requiredFields.every(
        key => this.customFields[key] != null && this.customFields[key] !== ''
      )
    },
    hasAllPersonalInformationsRequired() {
      const hasAllpersonalInformations = donationRequiredPersonalInformationsFields.every(
        key => this.personalInformations[key] != null && this.personalInformations[key] !== ''
      )
      const hasAllCompanyInformations = donationRequiredCompanyFields.every(
        key => this.personalInformations[key] != null && this.personalInformations[key] !== ''
      )
      return this.personalInformations.isCompany
        ? hasAllpersonalInformations && hasAllCompanyInformations
        : hasAllpersonalInformations
    },
    isActiveBlock() {
      return (
        this.selectedTier &&
        this.hasAllPersonalInformationsRequired &&
        this.hasAllCustomFieldsRequired
      )
    }
  },
  mounted() {
    this.setTipSuggestedFromTierAmount(this.price)
  },
  watch: {
    price(newValue) {
      this.tipData = getTipSuggestion(newValue, 1)
      this.setTipSuggestedFromTierAmount(this.price)
    },
    tipSelected() {
      this.tipData.tipChosen = this.tipSelected
      this.$emit('set-tip', this.tipData)
    }
  },
  methods: {
    convertToEuros(price) {
      return functions.formatThousands(functions.convertToEuros(price))
    },
    getTrackingData() {
      return {
        payment_amount: parseFloat(functions.convertToEuros(this.price)).toFixed(2),
        payment_frequency_type: this.selectedTier?.tierType,
        contribution_default_amount: parseFloat(
          functions.convertToEuros(this.tipData.tipSuggestion)
        ).toFixed(2),
        contribution_amount: parseFloat(functions.convertToEuros(this.tipSelected)).toFixed(2)
      }
    },
    trackFirstClick(name) {
      this.onFirstClick(name, {
        step: 0,
        step_name: 'Récapitulatif'
      })
    },
    displayModal(name) {
      if (name === 'explication') this.displayModalContributionExplication = true
      if (name === 'selection') this.displayModalContributionSelection = true
      this.trackFirstClick('First Interaction Campaign')
    },
    openModalModify() {
      this.displayModal('selection')
      this.onTipsUpdate('Tips Info set', this.tipData, this.getTrackingData())
    }
  }
}
</script>

<style lang="scss">
.DonationSummary {
  .Block--Content {
    text-align: left;
  }

  .ContributionNotice {
    margin-top: $ha-spacing;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-radius: $ha-radius-regular;
    border-collapse: collapse;

    th {
      font-weight: $ha-weight-semibold;
    }

    td,
    th {
      padding: $ha-spacing-tiny;
      border: $ha-border-width-large solid set-alpha(border, 1);
      border-collapse: collapse;

      &:nth-child(2) {
        width: $ha-unit * 11;
        overflow-x: auto;
        white-space: nowrap;
      }
    }
  }

  .RequiredFields {
    font-weight: $ha-weight-semibold;
  }

  &--Validation {
    padding: $ha-spacing-medium 0;
    text-align: left;
    border-top: $ha-border-width-regular solid set-alpha(border, 1);
  }
}
</style>
